import useCRUD from "../../hooks/useCRUD";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import * as Yup from "yup";
import Title from "../../components/Title";
import TitleHeading from "../../components/TitleHeading";
import Breadcrumb from "../../components/Breadcrumb";
import Stage from "../../components/Stage";
import {Form, Formik} from "formik";
import TextInput from "../../components/TextInput";
import ButtonBar from "../../components/ButtonBar";
import Button from "../../components/Button";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon} from "../../components/Icons";
import FormErrors from "../../components/FormErrors";
import Modal from "../../components/Modal";
import ErrorModal from "../../components/ErrorModal";
import Accordion from "../../components/Accordion";
import Row from "../../components/Row";
import SelectInput from "../../components/SelectInput";
import TextareaInput from "../../components/TextareaInput";
import {TemplateContext} from "../../contexts/TemplateContext";

export default function EditOfferTemplate() {

  const crud = useCRUD();
  const navigate = useNavigate();
  const params = useParams();

  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [item, setItem] = useState(null);
  const [useLogoGalleries, setUseLogoGalleries] = useState();
  const [useDocuments, setUseDocuments] = useState();
  const [useTestimonials, setUseTestimonials] = useState();
  const [testimonialsAvailable, setTestimonialsAvailable] = useState(false);
  const [reloadKey, setReloadKey] = useState(0);
  const [contactPersonsAvailable, setContactPersonsAvailable] = useState(false);
  const [useContactPerson, setUseContactPerson] = useState();
  const [useTeamMembers, setUseTeamMembers] = useState();
  const [headerImageAvailable, setHeaderImageAvailable] = useState(false);
  const [useHeaderImage, setUseHeaderImage] = useState();
  const [uspAvailable, setUspAvailable] = useState(false);
  const [useUsp, setUseUsp] = useState();
  const [faqAvailable, setFaqAvailable] = useState(false);
  const [useFaq, setUseFaq] = useState();
  const [usePictureGalleries, setUsePictureGalleries] = useState(false);
  const [ctaAvailable, setCtaAvailable] = useState(false);
  const [useCta, setUseCta] = useState();
  const [translationAvailable, setTranslationAvailable] = useState(false);
  const [useTranslation, setUseTranslation] = useState();
  const [videoAvailable, setVideoAvailable] = useState(false);
  const [useIntroductionVideo, setUseIntroductionVideo] = useState(false);
  const [useOfferVideo, setUseOfferVideo] = useState(false);
  const [letterAvailable, setLetterAvailable] = useState(false);
  const [useLetter, setUseLetter] = useState(false);
  const [selectLetter, setSelectLetter] = useState(false);
  const templateContext = useContext(TemplateContext);

  const arraysAreIdentical =
    (a, b) => a.length === b.length && a.every((element, index) => element?.toString() === b[index]?.toString());

  useEffect(() => {
    setLoading(true);
    crud.data.read({entity: 'offer', id: parseInt(params.id)})
      .then(async result => {

        setUseContactPerson(result.contact_person_id != null && result.contact_person_id.length > 0);
        setUseHeaderImage(result.header_image_id != null && result.header_image_id.length > 0);
        setUseTestimonials(result.testimonial_group_id != null && result.testimonial_group_id.length > 0);
        setUseUsp(result.usp_group_id != null && result.usp_group_id.length > 0);
        setUseFaq(result.faq_group_id != null && result.faq_group_id.length > 0);
        setUseCta(result.cta_id != null && result.cta_id.length > 0);
        setUseTranslation(result.translation_id != null && result.translation_id.length > 0);
        setUseLetter(result.letter != null && result.letter.length > 0);
        setUseIntroductionVideo(result.introduction_video_id != null && result.introduction_video_id.length > 0);
        setUseOfferVideo(result.offer_video_id != null && result.offer_video_id.length > 0);

        await crud.data.bulk.read({
          entity: 'offer_has_file',
          filter: crud.filter.equals('offer_id', params.id),
          page_size: 1000
        })
          .then(
            offer_has_file_result => {
              result.documents = [];
              if (offer_has_file_result.total) {
                crud.data.bulk.read({
                  entity: "document",
                  page_size: 1000,
                  filter: {
                    group: 'or',
                    components: offer_has_file_result.items.map(document => (
                      {property: 'file_id', operator: 'equals', value: document.file_id}
                    ))
                  }
                })
                  .then((document_result) => {
                    if (document_result.total) {
                      setUseDocuments(true);
                      document_result.items.forEach((document) => {
                        result.documents.push(document.id);
                      });
                    }
                  });
              } else {
                setUseDocuments(false);
              }
            }
          )
          .catch(errors => setErrors([errors]));

        await crud.data.bulk.read({
          entity: 'offer_has_logo_gallery',
          filter: crud.filter.equals('offer_id', params.id),
          page_size: 1000
        })
          .then(
            logo_gallery_result => {
              result.logo_gallery = [];
              if (logo_gallery_result.total) {
                setUseLogoGalleries(true);
                logo_gallery_result.items.forEach(logo_gallery => {
                  result.logo_gallery.push(logo_gallery.logo_gallery_id);
                });
              } else {
                setUseLogoGalleries(false);
              }
            }
          )
          .finally()
          .catch(errors => {setErrors([errors]);
          });
        await crud.data.bulk.read({
          entity: 'offer_has_picture_gallery',
          filter: crud.filter.equals('offer_id', params.id),
          page_size: 1000
        })
          .then(
            picture_gallery_result => {
              result.picture_galleries = [];
              if (picture_gallery_result.total > 0) {
                setUsePictureGalleries(true);
                picture_gallery_result.items.forEach(picture_gallery => {
                  result.picture_galleries.push(picture_gallery.picture_gallery_id);
                });
              } else {
                if (result.picture_gallery_id) {
                  setUsePictureGalleries(true);
                  result.picture_galleries.push(result.picture_gallery_id);
                } else {
                  setUsePictureGalleries(false);
                }
              }
            }
          )
          .finally()
          .catch(errors => {setErrors([errors]);});
        await crud.data.bulk.read({
          entity: 'offer_has_team_member',
          filter: crud.filter.equals('offer_id', params.id),
          page_size: 1000
        })
          .then(
            team_member_result => {
              result.team_members = [];
              if (team_member_result.total) {
                setUseTeamMembers(true);
                team_member_result.items.forEach(team_member => {
                  result.team_members.push(team_member.team_member_id);
                });
              } else {
                setUseTeamMembers(false);
              }
            }
          )
          .finally()
          .catch(errors => {
            setErrors([errors]);
          });
        setItem({...result});
      })
      .finally(() => {
        setLoading(false);
      })
      .catch(errors => setErrors([errors]));

  }, [crud.data, params.id, reloadKey, crud.filter]);

  const validationSchema = Yup.object({
    title: Yup.string().required('Bitte eine Bezeichnung für die Vorlage angeben'),
  });

  const name = contactPerson => {
    let result  = ((contactPerson.first_name ?? '') + ' ' + (contactPerson.last_name ?? '')).trim();
    return result.length > 0 ? result : contactPerson.e_mail;
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      crud.data.bulk.read({entity: "contact_person", page_size: 0}),
      crud.data.bulk.read({entity: "header_image", page_size: 0}),
      crud.data.bulk.read({entity: "usp_group", page_size: 0}),
      crud.data.bulk.read({entity: "faq_group", page_size: 0}),
      crud.data.bulk.read({entity: "cta", page_size: 0}),
      crud.data.bulk.read({entity: "translation", page_size: 0}),
      crud.data.bulk.read({entity: "letter", page_size: 0}),
      crud.data.bulk.read({entity: "video", page_size: 0}),
      crud.data.bulk.read({entity: "testimonial_group", page_size: 0}),
    ])
      .then(([contact_person, header_image, usp, faq, cta, translation, letter, video, testimonial_group]) => {
        setContactPersonsAvailable(contact_person.total > 0);
        setHeaderImageAvailable(header_image.total > 0);
        setUspAvailable(usp.total > 0);
        setFaqAvailable(faq.total > 0);
        setCtaAvailable(cta.total > 0);
        setTranslationAvailable(translation.total > 0);
        setLetterAvailable(letter.total > 0);
        setVideoAvailable(video.total > 0);
        setTestimonialsAvailable(testimonial_group.total > 0);
      })
      .finally(() => setIsLoading(false))
      .catch(errors => setErrors([errors]));
  }, [crud.data.bulk]);

  async function onSubmit(values, {setSubmitting}) {

    let updateOffer = {
      title : values.title,
      is_template : 1,
      contact_person_id: !useContactPerson ? null : values.contact_person_id,
      header_image_id: !useHeaderImage ? null : values.header_image_id,
      testimonial_group_id: !useTestimonials ? null : values.testimonial_group_id,
      faq_group_id: !useFaq ? null : values.faq_group_id,
      usp_group_id: !useUsp ? null : values.usp_group_id,
      picture_gallery_id: null,
      cta_id: !useCta ? null : values.cta_id,
      translation_id: !useTranslation ? null : values.translation_id,
      letter: !useLetter ? null : values.letter,
      introduction_video_id: !useIntroductionVideo ? null : values.introduction_video_id,
      offer_video_id: !useOfferVideo ? null : values.offer_video_id
    };

    crud.data.update({entity: 'offer', id : item.id, update: {...updateOffer}})
      .then(async () => {
        // documents
        if (values.documents.sort().toString() !== item.documents.sort().toString() || !useDocuments) {
          let delete_items = item.documents;
          if (useDocuments) {
            values.documents.map(async (document_id) => {
              if (!item.documents.includes(document_id.toString())) {
                await crud.data.read({entity: 'document', id: document_id})
                  .then(async (document) => {
                    await crud.data.create({
                      entity: 'offer_has_file',
                      item: {
                        offer_id: item.id,
                        file_id: document.file_id,
                        type: 'other',
                      }
                    })
                      .catch(errors => setErrors([errors]));
                  })
                  .catch(errors => setErrors([errors]));
              } else {
                delete_items.splice(delete_items.indexOf(document_id.toString()), 1);
              }
            });
          }
          if (delete_items.length > 0) {
            crud.data.bulk.read({
              entity: "document",
              page_size: 1000,
              filter: {
                group: 'or',
                components: delete_items.map(document => (
                  {property: 'id', operator: 'equals', value: document}
                ))
              }
            })
              .then((document_result) => {
                crud.data.bulk.delete({
                  entity: 'offer_has_file',
                  filter: {
                    group: 'or',
                    components: document_result.items.map(delete_item => ({group: 'and', components: [
                      {property: 'offer_id', operator: 'equals', value: item.id},
                      {property: 'file_id', operator: 'equals', value: delete_item.file_id}
                    ]}))
                  }
                });

              }).catch(errors => setErrors([errors]));
          }
        }
        // logo galerien
        if (values.logo_galleries.sort().toString() !== item.logo_gallery.sort().toString() || !useLogoGalleries) {
          let delete_galleries = item.logo_gallery;
          if (useLogoGalleries) {
            values.logo_galleries.map(async (gallery_id) => {
              if (!item.logo_gallery.includes(gallery_id.toString())) {
                await crud.data.create({
                  entity: 'offer_has_logo_gallery',
                  item: {
                    offer_id: item.id,
                    logo_gallery_id: gallery_id
                  }
                })
                  .catch(errors => setErrors([errors]));
              } else {
                delete_galleries.splice(delete_galleries.indexOf(gallery_id.toString()), 1);
              }
            });
          }
          if (delete_galleries.length > 0) {
            await crud.data.bulk.delete({
              entity: 'offer_has_logo_gallery',
              filter: {
                group: 'or',
                components: delete_galleries.map(delete_item => ({group: 'and', components: [
                  {property: 'offer_id', operator: 'equals', value: item.id},
                  {property: 'logo_gallery_id', operator: 'equals', value: delete_item}
                ]}))
              }
            });
          }
        }

        // picture galleries
        const existingPictureGalleryIds = await crud.data.bulk.read({
          entity: 'offer_has_picture_gallery',
          filter: crud.filter.equals('offer_id', item.id)
        }).then(bulkReadResult => bulkReadResult.items.map(item => item.id));
        let targetPictureGalleryIds = usePictureGalleries ? values.picture_galleries : [];
        if (!arraysAreIdentical(existingPictureGalleryIds, targetPictureGalleryIds)) {
          if (existingPictureGalleryIds.length > 0) {
            await crud.data.bulk.delete({
              entity: 'offer_has_picture_gallery',
              filter: crud.filter.equals('offer_id', item.id)
            });
          }
          if (targetPictureGalleryIds.length > 0) {
            await crud.data.bulk.create({
              entity: 'offer_has_picture_gallery',
              items: targetPictureGalleryIds.map(id => ({offer_id: item.id, picture_gallery_id: id}))
            });
          }
        }

        // team-member
        if (values.team_members.sort().toString() !== item.team_members.sort().toString() || !useTeamMembers) {
          let delete_team_members = item.team_members;
          if (useTeamMembers) {
            values.team_members.map(async (team_member_id) => {
              if (!item.team_members.includes(team_member_id.toString())) {
                await crud.data.create({
                  entity: 'offer_has_team_member',
                  item: {
                    offer_id: item.id,
                    team_member_id: team_member_id
                  }
                })
                  .catch(errors => setErrors([errors]));
              } else {
                delete_team_members.splice(delete_team_members.indexOf(team_member_id.toString()), 1);
              }
            });
          }
          if (delete_team_members.length > 0) {
            await crud.data.bulk.delete({
              entity: 'offer_has_team_member',
              filter: {
                group: 'or',
                components: delete_team_members.map(delete_item => ({group: 'and', components: [
                  {property: 'offer_id', operator: 'equals', value: item.id},
                  {property: 'team_member_id', operator: 'equals', value: delete_item}
                ]}))
              }
            });
          }
        }
      })
      .finally(() => {
        setSubmitting(false);
        setReloadKey(reloadKey + 1);
        setShowSuccess(true);
      })
      .catch(errors => setErrors([errors]));
  }

  const [liteRestrictions, setLiteRestrictions] = useState(true);
  useEffect(() => {
    crud.data.read({entity: "settings", id: 1})
      .then(settings => setLiteRestrictions(settings.subscription_plan === "lite"));
  }, [crud.data]);

  return (
    <main>
      <Title>
        <TitleHeading>Angebots Vorlage bearbeiten</TitleHeading>
        <Breadcrumb>
          <NavLink to="/angebots-vorlagen">Vorlagen</NavLink>
          Bearbeiten
        </Breadcrumb>
      </Title>
      {!loading &&
      <Stage>
        <Formik
          initialValues={{
            title: item.title,
            contact_person_id: item.contact_person_id,
            team_members: item.team_members,
            header_image_id: item.header_image_id,
            testimonial_group_id: item.testimonial_group_id,
            usp_group_id: item.usp_group_id,
            faq_group_id: item.faq_group_id,
            letter: item.letter ? item.letter : '',
            introduction_video_id: item.introduction_video_id,
            offer_video_id: item.offer_video_id,
            picture_galleries: item.picture_galleries,
            logo_galleries: item.logo_gallery,
            cta_id: item.cta_id,
            documents: item.documents,
            translation_id: item.translation_id
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          onReset={() => setShowSuccess(false)}
        >
          {({isSubmitting, touched, errors, setFieldValue}) => (
            <Form className="mt-1-75">
              <TextInput
                name="title" label="Name" placeholder="Bsp. Standard-Vorlage"
                className="mb-1"
              />

              <Accordion
                className="mt-1-75" title="Ansprechpartner deiner Firma" active={useContactPerson}
                onToggle={() => {
                  setUseContactPerson(!useContactPerson);
                }}
              >
                {
                  isLoading &&
                  <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <LoaderIcon/>
                  </div>
                }
                {
                  !contactPersonsAvailable &&
                  !isLoading &&
                  <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <p>
                      Du hast bisher keine Ansprechpartner angelegt.<br/>
                      Bitte gehe zu Angebotsdaten &gt; <NavLink to="/ansprechpartner">Ansprechpartner</NavLink>
                    </p>
                  </div>
                }
                {
                  contactPersonsAvailable &&
                  !isLoading &&
                  <Row lgWidth="50%">
                    <SelectInput
                      label="Ansprechpartner" name="contact_person_id" menuPosition="fixed" entity="contact_person"
                      getOptionLabel={name}
                    />
                  </Row>
                }
              </Accordion>
              {
                templateContext.template && templateContext.template.exclusiveFeatures.includes('team') &&
                <Accordion
                  title="Teammitglieder" active={useTeamMembers}
                  onToggle={() => {setUseTeamMembers(!useTeamMembers);}}
                >
                  <SelectInput
                    label="Teammitglieder" name="team_members" menuPosition="fixed" entity="team_member"
                    getOptionLabel={item => `${item.first_name.trim()} ${item.last_name.trim()}`} isMulti isClearable
                  />
                </Accordion>
              }
              <Accordion
                title="Titelbild" active={useHeaderImage}
                onToggle={() => {
                  setUseHeaderImage(!useHeaderImage);
                }}
              >
                {
                  isLoading &&
                  <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <LoaderIcon/>
                  </div>
                }
                {
                  !headerImageAvailable &&
                  !isLoading &&
                  <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <p>
                      Du hast bisher keine Titelbilder hochgeladen.<br/>
                      Bitte gehe zu Angebotsdaten &gt; <NavLink to="/titelbilder">Titelbilder</NavLink>
                    </p>
                  </div>
                }
                {
                  headerImageAvailable &&
                  !isLoading &&
                  <Row lgWidth="50%">
                    <SelectInput
                      label="Titelbilder" name="header_image_id" menuPosition="fixed" entity="header_image"
                      getOptionLabel={item => (item.name).trim()}
                    />
                  </Row>
                }
              </Accordion>
              <Accordion
                title="Anschreiben" active={useLetter}
                onToggle={() => {
                  setUseLetter(!useLetter);
                }}
              >
                {
                  isLoading &&
                  <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <LoaderIcon/>
                  </div>
                }
                {
                  !letterAvailable &&
                  !isLoading &&
                  <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <p>
                      Du hast bisher keine Anschreiben hochgeladen.<br/>
                      Bitte gehe zu Angebotsdaten &gt; <NavLink to="/anschreiben">Titelbilder</NavLink>
                    </p>
                  </div>
                }
                {
                  letterAvailable &&
                  !isLoading &&
                  <Row lgWidth="50%">
                    { !selectLetter ?
                      <ButtonBar align="left">
                        <Button text="Anschreiben auswählen" onClick={() => setSelectLetter(true)}/>
                      </ButtonBar>
                      :
                      <SelectInput onChange={(value) => {
                        setFieldValue('letter', value.content);
                        setSelectLetter(false);
                      }} className="mt-1-75" name="letterSelection" entity="letter"
                      getOptionLabel={item => item.title} menuPosition="fixed"/>
                    }
                  </Row>
                }
                <TextareaInput
                  className="mt-1-75" name="letter" label="Anschreiben"
                  placeholder={
                    "Bsp. Vielen Dank, dass ich mich Ihrem Unternehmen vorstellen durfte. " +
                    "Folgend möchte ich Ihnen unser Unternehmen kurz vorstellen und unser Angebot erläutern."
                  }
                />
              </Accordion>
              <Accordion
                title="Begrüßungsvideo" active={useIntroductionVideo}
                onToggle={() => {
                  setUseIntroductionVideo(!useIntroductionVideo);
                }}
              >
                {
                  isLoading &&
                  <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <LoaderIcon/>
                  </div>
                }
                {
                  !videoAvailable &&
                  !isLoading &&
                  <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <p>
                      Du hast bisher keine Videos hochgeladen.<br/>
                      Bitte gehe zu Angebotsdaten &gt; <NavLink to="/videos-und-memos">Videos & Memos</NavLink>
                    </p>
                  </div>
                }
                {
                  videoAvailable &&
                  !isLoading &&
                  <Row lgWidth="50%">
                    <SelectInput name="introduction_video_id" entity="video" getOptionLabel={item => item.name}
                      menuPosition="fixed"/>
                  </Row>
                }
              </Accordion>
              <Accordion
                title="Angebotsvideo" active={useOfferVideo}
                onToggle={() => {
                  setUseOfferVideo(!useOfferVideo);
                }}
              >
                {
                  isLoading &&
                  <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <LoaderIcon/>
                  </div>
                }
                {
                  !videoAvailable &&
                  !isLoading &&
                  <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <p>
                      Du hast bisher keine Videos hochgeladen.<br/>
                      Bitte gehe zu Angebotsdaten &gt; <NavLink to="/videos-und-memos">Videos & Memos</NavLink>
                    </p>
                  </div>
                }
                {
                  videoAvailable &&
                  !isLoading &&
                  <Row lgWidth="50%">
                    <SelectInput name="offer_video_id" entity="video" getOptionLabel={item => item.name}
                      menuPosition="fixed"/>
                  </Row>
                }
              </Accordion>
              <Accordion
                title="Bewertungen" active={useTestimonials}
                onToggle={() => setUseTestimonials(!useTestimonials)}
              >
                {
                  isLoading &&
                  <div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <LoaderIcon/>
                  </div>
                }
                {
                  !testimonialsAvailable &&
                  !isLoading &&
                  <div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <p>
                      Du hast bisher keine Bewertungen angelegt.<br/>
                      Bitte gehe zu Angebotsdaten &gt; <NavLink to="/bewertungsgruppen">Bewertungen</NavLink>
                    </p>
                  </div>
                }
                {
                  testimonialsAvailable &&
                  !isLoading &&
                  <>
                    <Row lgWidth="50%">
                      <SelectInput
                        label="Gruppe" name="testimonial_group_id" menuPosition="fixed" entity="testimonial_group"
                        getOptionLabel={item => (item.name).trim()}
                      />
                    </Row>
                  </>
                }
              </Accordion>
              {
                !liteRestrictions &&
                <Accordion
                  title="Unternehmensvorteile" active={useUsp}
                  onToggle={() => {
                    setUseUsp(!useUsp);
                  }}
                >
                  {
                    isLoading &&
                  <div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <LoaderIcon/>
                  </div>
                  }
                  {
                    !uspAvailable &&
                    !isLoading &&
                  <div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <p>
                      Du hast bisher keine Vorteile angelegt.<br/>
                      Bitte gehe zu Angebotsdaten &gt; <NavLink to="/usp-gruppen">Unternehmensvorteile</NavLink>
                    </p>
                  </div>
                  }
                  {
                    uspAvailable &&
                    !isLoading &&
                    <>
                      <Row lgWidth="50%">
                        <SelectInput
                          label="Gruppe" name="usp_group_id" menuPosition="fixed" entity="usp_group"
                          getOptionLabel={item => (item.name).trim()}
                        />
                      </Row>
                    </>
                  }
                </Accordion>
              }
              {
                !liteRestrictions &&
                <Accordion
                  title="FAQs" active={useFaq}
                  onToggle={() => {
                    setUseFaq(!useFaq);
                  }}
                >
                  {
                    isLoading &&
                <div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <LoaderIcon/></div>
                  }
                  {
                    !faqAvailable &&
                    !isLoading &&
                <div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <p>
                    Du hast bisher keine FAQ-Gruppe angelegt.<br/>
                    Bitte gehe zu Angebotsdaten &gt; <NavLink to="/faq-gruppen">FAQ</NavLink>
                  </p>
                </div>
                  }
                  {
                    faqAvailable && !isLoading &&
                  <>
                    <Row lgWidth="50%">
                      <SelectInput
                        label="FAQ Paket" name="faq_group_id" menuPosition="fixed" entity="faq_group"
                        getOptionLabel={item => (item.name).trim()}
                      />
                    </Row>
                  </>
                  }
                </Accordion>
              }
              <Accordion
                title="Galerien" active={usePictureGalleries}
                onToggle={() => {
                  setUsePictureGalleries(!usePictureGalleries);
                }}
              >
                <Row lgWidth="50%">
                  <SelectInput
                    label="Galerien" name="picture_galleries" menuPosition="fixed" entity="picture_gallery" isMulti
                    getOptionLabel={item => (item.headline).trim()}
                  />
                </Row>
              </Accordion>
              {
                templateContext.template && templateContext.template.exclusiveFeatures.includes('cta') &&
                <Accordion
                  title="Call-To-Action" active={useCta}
                  onToggle={() => {setUseCta(!useCta);}}
                >
                  {
                    isLoading &&
                    <div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <LoaderIcon/>
                    </div>
                  }
                  {
                    !ctaAvailable &&
                    !isLoading &&
                    <div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <p>
                        Du hast bisher keine Call-To-Actions angelegt.<br/>
                        Bitte gehe zu Angebotsdaten &gt; <NavLink to="/cta">Call-To-Actions</NavLink>
                      </p>
                    </div>
                  }
                  {
                    ctaAvailable &&
                    !isLoading &&
                    <>
                      <Row lgWidth="50%">
                        <SelectInput
                          label="Call-To-Action" name="cta_id" menuPosition="fixed" entity="cta"
                          getOptionLabel={item => (item.headline).trim()}
                        />
                      </Row>
                    </>
                  }
                </Accordion>
              }
              {
                templateContext.template && templateContext.template.exclusiveFeatures.includes('logo_gallery') &&
                <Accordion
                  title="Logo-Galerien" active={useLogoGalleries}
                  onToggle={() => {setUseLogoGalleries(!useLogoGalleries);}}
                >
                  <SelectInput
                    label="Logo-Galerien" name="logo_galleries" menuPosition="fixed" entity="logo_gallery"
                    getOptionLabel={item => (item.headline).trim()} isMulti isClearable
                  />
                </Accordion>
              }
              <Accordion
                title="Weitere Dokumente" active={useDocuments}
                onToggle={() => {
                  setUseDocuments(!useDocuments);
                }}
              >
                <SelectInput
                  label="Dokumente" name="documents" menuPosition="fixed" entity="document" isMulti isClearable
                  getOptionLabel={item => item.name}
                />
              </Accordion>

              <Accordion
                title="Texte" active={useTranslation}
                onToggle={() => {
                  setUseTranslation(!useTranslation);
                }}
              >
                {
                  isLoading &&
                  <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <LoaderIcon/>
                  </div>
                }
                {
                  !translationAvailable &&
                  !isLoading &&
                  <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <p>
                      Du hast bisher keine Textänderungen erstellt.<br/>
                      Bitte gehe zu Texte &gt; <NavLink to="/texte">Texte</NavLink>
                    </p>
                  </div>
                }
                {
                  translationAvailable &&
                  !isLoading &&
                  <Row lgWidth="50%">
                    <SelectInput
                      label="Texte" name="translation_id" menuPosition="fixed" entity="translation"
                      getOptionLabel={item => (item.name).trim()}
                    />
                  </Row>
                }
              </Accordion>

              <ButtonBar>
                <Button
                  color="gray" icon={<ChevronLeftIcon/>} iconPosition="left" text="Zurück"
                  onClick={() => navigate(-1)}/>
                <Button icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>}
                  text="Speichern" type="submit" disabled={isSubmitting}/>
              </ButtonBar>
              <FormErrors touched={touched} errors={errors}/>
              <Modal
                title="Die Angebotsvorlage wurde erfolgreich geändert" show={showSuccess}
                onDismiss={() => navigate('/angebots-vorlagen')}
              >
                <ButtonBar>
                  <Button text="Zurück zur Übersicht" onClick={() => navigate("/angebots-vorlagen")}/>
                </ButtonBar>
              </Modal>
            </Form>
          )}
        </Formik>
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
      }
    </main>
  );
}
