import './record.scss';
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AudioOnly} from "../../../images/CreateVideoGraphics";
import {RecordingContext} from "../../../contexts/RecordingContext";
import Button from "../../../components/Button";

export default function Record(props) {

  const previewRef = useRef();
  const mediaRecorder = useRef(null);
  const mediaChunks = useRef([]);
  const mediaStream = useRef(null);
  const [error, setError] = useState('');
  const [startPrepareRecodingTimer, setStartPrepareRecodingTimer] = useState(false);
  const [recording, setRecording] = useState(false);
  const [counter, setCounter] = useState(5);
  const [startSafariScreencast, setStartSafariScreencast] = useState(false);
  const recordingContext = useContext(RecordingContext);

  const navigate = useNavigate();

  // Get the user-agent string
  let userAgentString = navigator.userAgent;

  // Detect Chrome
  let isChrome = userAgentString.indexOf("Chrome") > -1;

  // Detect Safari
  let isSafari = userAgentString.indexOf("Safari") > -1;

  // Discard Safari since it also matches Chrome
  if ((isChrome) && (isSafari)) {
    isSafari = false;
  }

  let screencast = useRef(undefined);

  const getMediaStream = useCallback(async () => {
    try {
      mediaStream.current = await window.navigator.mediaDevices.getUserMedia(props.settings);
      if (props.settings.screencast) {
        try {
          if (!screencast.current) {
            screencast.current = await window.navigator.mediaDevices.getDisplayMedia({video:true});
          }
          screencast.current.addTrack(mediaStream.current.getAudioTracks()[0]);
          mediaStream.current = screencast.current;
        } catch (error) {
          console.error(error);
          navigate(-1);
        }
      }
      previewRef.current.srcObject = mediaStream.current;
      previewRef.current.muted = true;
    } catch (error) {
      setError(error.name);
    }
  }, [props.settings, navigate]);

  useEffect(() => {
    if (!window.MediaRecorder) {
      setError('Keine Browserunterstützung');
    }

    if (!window.navigator.mediaDevices.getDisplayMedia) {
      setError('Ihr Browser unterstützt keine Bildschirmaufnahme');
    }

    if (!mediaStream.current && (!isSafari || recordingContext.type !== 'screencast')) {
      getMediaStream().catch();
    }

    return () => {
      if (mediaStream.current) {
        mediaStream.current.getTracks().forEach((track) => track.stop());
      }
      if (mediaRecorder.current) {
        mediaRecorder.current.stop();
      }
    };
  }, [getMediaStream, isSafari, recordingContext.type]);

  useEffect(() => {
    const timer = counter > 0 && startPrepareRecodingTimer && setInterval(() => {
      setCounter(counter - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [counter, startPrepareRecodingTimer]);

  const startPrepareTimer = () => {
    setStartPrepareRecodingTimer(true);
    const timeout = setTimeout(() => {
      setStartPrepareRecodingTimer(false);
      startRecording();
      setCounter(5);
      clearTimeout(timeout);
    }, counter * 1000);
  };

  const startRecording = async () => {

    setError('');
    setRecording(true);

    const isStreamEnded = mediaStream.current
      .getTracks()
      .some(track => track.readyState === 'ended');

    if (isStreamEnded) {
      await getMediaStream();
    }

    // User blocked the permissions (getMediaStream errored out)
    if (!mediaStream.current.active) {
      return;
    }

    let type = '';
    let codecs = '';
    if ((props.settings.video || props.settings.screencast === true) && props.settings.audio === true) {
      if (MediaRecorder.isTypeSupported('video/webm; codecs:vp8,opus')) {
        type = 'video/webm';
        codecs = 'vp8,opus';
      } else {
        type = 'video/mp4';
        codecs = 'h264,acc';
      }
    } else if (props.settings.video === false && props.settings.audio === true) {
      if (MediaRecorder.isTypeSupported('audio/ogg; codecs:opus')) {
        type = 'audio/ogg';
        codecs = 'opus';
      } else if (MediaRecorder.isTypeSupported('audio/webm; codecs:opus')) {
        type = 'audio/webm';
        codecs = 'opus';
      } else {
        type = 'audio/mp4';
        codecs = 'acc';
      }
    }

    mediaRecorder.current = new MediaRecorder(mediaStream.current, {
      mimeType: `${type}; codecs:${codecs}`
    });
    mediaRecorder.current.ondataavailable = (({ data }) => {
      data.size && mediaChunks.current.push(data);
    });
    mediaRecorder.current.onstop = () => {
      mediaChunks.current.length && props.setVideoBlob(new Blob(mediaChunks.current, { type: type }));
    };
    mediaRecorder.current.onerror = () => setError("Fehler bei der Aufnahme");
    mediaRecorder.current.start();

  };

  const stopRecording = useCallback(() => {
    if (mediaRecorder?.current.state !== "inactive") {
      mediaChunks.current = [];
      navigate('../vorschau');
    }
  }, [navigate]);

  useEffect(() => {
    const onEnter = (event) => {
      if (event.key === "Enter" && recording) {
        stopRecording();
      }
    };

    window.addEventListener("keyup", onEnter);
    return () => {
      window.removeEventListener("keyup", onEnter);
    };
  }, [recording, stopRecording]);

  const startScreencast = async () => {
    screencast.current = await window.navigator.mediaDevices.getDisplayMedia({video:true});
    getMediaStream().catch();
    setStartSafariScreencast(true);
  };

  return (
    <>
      {error.length === 0 && <div className="RecordVideo">
        {recordingContext.type === 'screencast' && isSafari && !startSafariScreencast &&
          <div style={{position: 'absolute', top: 'calc(50% - 24px)', left: 'calc(50% - 86px)'}}>
            <Button text="Bildschirm freigeben" type="button" onClick={startScreencast} />
          </div>
        }
        {!(recordingContext.type === 'screencast' && isSafari && !startSafariScreencast) && <div>
          {startPrepareRecodingTimer && <div className="recordStartOverlay">
            <div></div>
            <div>
              <div className="counter">{counter}</div>
              <h1>Mach dich bereit!</h1>
              <p>
                Sobald der Timer runtergelaufen ist, startet die&nbsp;
                {(recordingContext.type === 'screencast' ?
                  'Bildschirmaufnahme' : (recordingContext.type === 'memo' ? 'Memoaufnahme' : 'Videoaufnahme')
                )}.
              </p>
            </div>
          </div>}
          <div className={'videoContainer' + (recording ? ' recording' : '')}>
            <video id="preview" ref={previewRef} autoPlay></video>
            {!props.settings.video && !props.settings.screencast && <div className="audioOnly">
              <AudioOnly />
            </div>}
          </div>
          <div className="controlsContainer">
            {!recording && <button className="startRecording" onClick={startPrepareTimer}>REC</button>}
            {recording && <>
              <button className="stopRecording" onClick={stopRecording}></button>
              <p>Mit "Enter" kannst du die Aufnahme ebenfalls beenden.</p>
            </>}
          </div>
        </div>}
      </div>}
      {error.length > 0 && <div>{error}</div>}
    </>
  );
}
