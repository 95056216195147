import './Table.scss';
import React, {Fragment, useRef} from "react";
import {useEffect, useState} from "react";
import ErrorModal from "./ErrorModal";
import {RowContext} from "../contexts/RowContext";
import {Form, Formik} from "formik";
import {DoubleArrow, DragIcon, EmptyPackageIcon, LoaderIcon} from "./Icons";
import IconWithText from "./IconWithText";
import Pagination from "./Pagination";
import {ChevronDownIcon, ChevronUpIcon} from "./Icons";
import useCRUD from "./../hooks/useCRUD";
import {sessionStorage} from "../storage";
import TextInput from "./TextInput";
import SelectInput from "./SelectInput";
import useClaims from "../hooks/useClaims";
import Row from "./Row";
import Tooltip from "./Tooltip";
import Button from "./Button";

export default function Table({
  name, entity, defaultOrder, defaultOrderBy, filter, handleEmptyStorage, className, noSort, expand,
  searchProperties, dropdownProperty, dropdownLabel, dropdownFilterShowNull, excludeFilter, draggable = false, ...props
}) {
  if (draggable) {
    noSort = true;
  }
  const tableSettings = name ? (sessionStorage.get('tableSettings') ?? {})[name] ?? {} : {};

  const pageSize = 10;
  const [page, setPage] = useState(0);
  const oldPageRef = useRef(0);
  const crud = useCRUD();
  const claims = useClaims();
  const [totalRows, setTotalRows] = useState();
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showLoading, setShowLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [orderBy, setOrderBy] = useState(draggable ? 'order' : tableSettings.orderBy ?? defaultOrderBy ?? 'id');
  const [order, setOrder] = useState(draggable ? 'asc' : tableSettings.order ?? defaultOrder ?? 'asc');
  const [searchFilter, setSearchFilter] = useState();
  const [reloadKey, setReloadKey] =  useState(props.reloadKey);
  const [initialLoad, setInitialLoad] = useState(!!(searchProperties || dropdownProperty || excludeFilter));
  const [orderChanged, setOrderChanged] = useState(false);
  const [savingOrder, setSavingOrder] = useState(false);
  const [possibleDrop, setPossibleDrop] = useState(null);
  const [dragRow, setDragRow] = useState(null);
  useEffect(() => setReloadKey(props.reloadKey), [props.reloadKey]);

  useEffect(() => {
    name && sessionStorage.set(
      'tableSettings', {...(sessionStorage.get('tableSettings') ?? {}), [name]: {orderBy, order}}
    );
  }, [orderBy, order, name]);

  useEffect(() => {
    if (draggable && page !== oldPageRef.current) {
      oldPageRef.current = page;
      return;
    }
    setIsLoading(true);
    setTimeout(() => setInitialLoad(false), 1000);
    if (!initialLoad) {
      setShowLoading(false);
      const timeout = setTimeout(() => setShowLoading(true), 1000);
      crud.data.bulk.read({
        entity: entity,
        page: draggable ? 0 : page,
        page_size: draggable ? 9999 : pageSize,
        order_by: orderBy,
        order: order,
        filter:
          (filter && searchFilter && crud.filter.and(filter, ...searchFilter))
          || filter
          || searchFilter
      })
        .then(async result => {
          setRows(expand ? await crud.expand(result.items, expand) : result.items);
          setTotalRows(result.total);
          if (handleEmptyStorage && result.total === 0 && (!filter || Object.keys(filter).length === 0)) {
            handleEmptyStorage();
          }
        })
        .catch(errors => setErrors(errors))
        .finally(() => {
          clearTimeout(timeout);
          setShowLoading(false);
          setIsLoading(false);
        });
    }
  }, [entity, filter, handleEmptyStorage, orderBy, order, crud, expand, searchFilter, reloadKey,
    initialLoad, draggable, page]);

  const sortIndicator = (props) => {
    let indicator = '';
    if (props?.property) {
      indicator = orderBy === props.property && order === 'asc' ? <ChevronUpIcon/> : <ChevronDownIcon/>;
    }
    return indicator;
  };

  let timeoutId;
  const setFilter = values => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setInitialLoad(false);
      let filters = [];
      if (values.search_bar && searchProperties) {
        setSearchFilter({});
        searchProperties.forEach(
          prop => filters.push({operator: 'contains', property: prop, value: values.search_bar})
        );
      }
      let excludedFilters = [];
      if (excludeFilter.length > 0) {
        excludeFilter.forEach(item => {
          if (item.value === null) {
            excludedFilters.push({operator: 'isNotNull', property: item.property});
          } else {
            excludedFilters.push(crud.filter.or(
              {operator: 'notEquals', property: item.property, value: item.value},
              {operator: 'isNull', property: item.property}
            ));
          }
        });
      }
      if (values.dropdown && dropdownProperty) {

        let dropdownFilter = dropdownFilterShowNull
          ?  crud.filter.or(
            {operator: 'isNull', property: dropdownProperty},
            {operator: 'contains', property: dropdownProperty, value: values.dropdown}
          )
          : {operator: 'contains', property: dropdownProperty, value: values.dropdown}
        ;

        let allFilters =
          filters.length > 0
            ? crud.filter.and(
              crud.filter.or(...filters),
              crud.filter.or(
                {operator: 'contains', property: dropdownProperty, value: values.dropdown},
                {operator: 'isNull', property: dropdownProperty},
              )
            )
            : dropdownFilter;
        if (excludedFilters.length > 0) {
          allFilters = crud.filter.and(...excludedFilters, allFilters);
        }
        setSearchFilter(allFilters);
        return;
      }
      let allFilters = filters.length > 0 ? crud.filter.or(...filters) : null;
      if (excludedFilters.length > 0) {
        if (allFilters) {
          allFilters = crud.filter.and(...excludedFilters, allFilters);
        } else {
          allFilters = crud.filter.and(...excludedFilters);
        }
      }
      if (allFilters) {
        setSearchFilter(allFilters);
      }
    }, 250);
  };

  const dropdownOptions = contactPerson => {
    let result  = ((contactPerson.first_name ?? '') + ' ' + (contactPerson.last_name ?? '')).trim();
    return result.length > 0 ? result : contactPerson.e_mail;
  };

  const onDragStart = (e, index) => {
    setTimeout(() => setPossibleDrop({
      dropIndex: index,
      dragIndex: index,
      position: "above"
    }), 0);
  };
  const onDragOver = (e, index, setPosition = true) => {
    e.preventDefault();
    if (index === possibleDrop?.dragIndex || !possibleDrop) {
      return;
    }
    let position = null;
    if (setPosition) {
      position = ((e.target.getBoundingClientRect().y + e.target.getBoundingClientRect().height / 2) > e.clientY)
        ? "above"
        : "below";
    }

    setPossibleDrop({
      dropIndex: index,
      dragIndex: possibleDrop?.dragIndex,
      position
    });
  };
  const onDragEnd = e => {
    e.target.classList.remove("drag-item");
    e.target.classList.remove("drop-placeholder");
    if (
      possibleDrop
      && possibleDrop.dropIndex !== possibleDrop.dragIndex
      && !(possibleDrop.dropIndex - 1 === possibleDrop.dragIndex && possibleDrop.position === "above")
      && !(possibleDrop.dropIndex + 1 === possibleDrop.dragIndex && possibleDrop.position === "below")
    ) {
      let dropIndex = possibleDrop?.dropIndex;
      if (possibleDrop?.position === "above" && possibleDrop.dropIndex > possibleDrop.dragIndex) {
        dropIndex--;
      }
      if (possibleDrop.position === "below" && possibleDrop.dropIndex < possibleDrop.dragIndex) {
        dropIndex++;
      }
      setOrderChanged(true);
      const draggedItem = rows[possibleDrop?.dragIndex];
      const remainingItems = rows.filter((_, index) => index !== possibleDrop?.dragIndex);
      const newRows = [...remainingItems.slice(0, dropIndex), draggedItem, ...remainingItems.slice(dropIndex)];
      setRows(newRows);
    }
    setPossibleDrop(null);
  };

  const saveOrder = () => {
    setSavingOrder(true);
    const crudCalls = [];
    rows.forEach((row, rowIndex) => {
      crudCalls.push(crud.data.update({entity, id: row.id, update: {order: rowIndex}}));
    });
    Promise.all(crudCalls)
      .catch(error => setErrors(error))
      .finally(() => {
        setOrderChanged(false);
        setSavingOrder(false);
      });
  };

  return (
    <div className={'Table' + (className ? ` ${className}` : '')}>
      {
        draggable &&
        <Button
          text="Reihenfolge speichern" disabled={!orderChanged || savingOrder} onClick={() => saveOrder()}
          icon={savingOrder ? <LoaderIcon/> : null}
        />
      }

      {
        (searchProperties?.length > 0 || dropdownProperty) &&
        <Formik
          initialValues={{search_bar: '', dropdown: claims.user_id ?? null}}
          validate={values => setFilter(values)}
          onSubmit={(values, {setSubmitting}) => setSubmitting(false)}
          validateOnMount
        >
          {() => (
            <Form>
              <Row>
                {
                  searchProperties?.length > 0 &&
                  <TextInput name="search_bar" label="Suche"/>
                }
                {
                  dropdownProperty &&
                  <SelectInput
                    name="dropdown" getOptionLabel={dropdownOptions} menuPosition="fixed"
                    label={
                      dropdownFilterShowNull && dropdownLabel
                        ? <>{dropdownLabel}
                          <Tooltip>Es werden immer Einträge ohne {dropdownLabel} mit angezeigt</Tooltip></>
                        : dropdownLabel
                    }
                    entity={
                      dropdownProperty.endsWith('_id')
                        ? dropdownProperty.slice(0, dropdownProperty.length - 3)
                        : dropdownProperty
                    }/>
                }
              </Row>
              <hr/>
            </Form>
          )}
        </Formik>
      }
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              {draggable && <th/>}
              {
                React.Children.toArray(props.children).map(
                  (column, index) => {
                    let classes = [];
                    if (column.props.type) {
                      classes.push('type-' + column.props.type);
                    }
                    if (column.props?.property && !noSort && !column.props?.noSort) {
                      classes.push('sortable');
                      if (orderBy === column.props.property) {
                        classes.push('active');
                      }
                    }
                    return (
                      <th
                        className={classes.length > 0 ? classes.join(' ') : null} key={index}
                        style={column.props.style ?? null}
                      >
                        <div>
                          <span onClick={() => {
                            if (column.props?.property && !noSort && !column.props?.noSort) {
                              setOrderBy(column.props.property);
                              if (orderBy !== column.props.property) {
                                setOrder('desc');
                              } else {
                                setOrder(order === 'desc' ? 'asc' : 'desc');
                              }
                            }
                          }}>
                            {column.props.title} {!noSort && !column.props?.noSort && sortIndicator(column.props)}
                          </span>
                        </div>
                      </th>
                    );
                  }
                )
              }
            </tr>
          </thead>
          <tbody>
            {
              (showLoading || rows.length === 0) &&
              <tr>
                <td colSpan={React.Children.toArray(props.children).length}>
                  <IconWithText>
                    {
                      isLoading && showLoading
                        ? <><LoaderIcon/> Laden…</>
                        : <><EmptyPackageIcon/> Keine Einträge vorhanden.</>
                    }
                  </IconWithText>
                </td>
              </tr>
            }
            {
              !showLoading
              && rows.filter(
                (_, index) => draggable
                  ? (
                    (index >= (page * pageSize) && index < ((page + 1) * pageSize))
                    || (
                      possibleDrop?.dropIndex > page * pageSize + pageSize - 1
                      && index === page * pageSize + pageSize
                    )
                    || (possibleDrop?.dropIndex < page * pageSize && index === page * pageSize - 1)
                  )
                  : true
              ).map(
                (row, rowIndex) => {
                  const tds = React.Children.toArray(props.children).map(
                    (column, columnIndex) => {
                      let content = '';
                      if (typeof column.props.property === 'string') {
                        content = row[column.props.property];
                      }
                      if (typeof column.props.values === 'object') {
                        if (row.id in column.props.values) {
                          content = column.props.values[row.id];
                        } else if (typeof column.props.populate === 'function') {
                          column.props.populate(row);
                        }
                      }
                      if (column.props.transform) {
                        content = column.props.transform(content, row);
                      }
                      let classes = [];
                      if (column.props.type) {
                        classes.push('type-' + column.props.type);
                      }
                      let divStyle = null;
                      if (column.props.maxWidth) {
                        divStyle = {
                          maxWidth: column.props.maxWidth, overflow: 'hidden', textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        };
                      }
                      return (
                        <td className={classes.length > 0 ? classes.join(' ') : null} key={columnIndex}>
                          <div style={divStyle}>{content}{column.props.children}</div>
                        </td>
                      );
                    }
                  );
                  return (
                    <Fragment key={rowIndex}>
                      {
                        !!possibleDrop
                        && possibleDrop.dropIndex === rowIndex + page * pageSize
                        && possibleDrop.position === "above"
                        && <tr className="drop-placeholder">{dragRow}</tr>
                      }
                      <tr
                        draggable={draggable}
                        className={
                          (draggable ? "draggable" : "")
                          + (
                            possibleDrop?.dragIndex + (possibleDrop?.dropIndex < page * pageSize) === (
                              rowIndex + page * pageSize
                            ) ? " drag-item"
                              : ""
                          )
                          + (orderChanged  ? " order-changed" : "")
                        }
                        onDragStart={e => {
                          if (draggable && !savingOrder) {
                            onDragStart(e, rowIndex + page * pageSize);
                            setDragRow(
                              <>
                                <td className="dragging-icon"><DoubleArrow/></td>
                                <RowContext.Provider value={row}>{tds}</RowContext.Provider>
                              </>
                            );
                          }
                        }}
                        onDragOver={e => {
                          if (draggable && !savingOrder) {
                            let firstPageIndex = page * pageSize;
                            let lastPageIndex = page * pageSize + pageSize - 1;
                            // Vorherige Seite, dann auf ersten Eintrag ziehen
                            if ((firstPageIndex > possibleDrop?.dropIndex) && rowIndex === 0) {
                              setPossibleDrop({...possibleDrop, dropIndex: firstPageIndex, position: "above"});
                            }
                            // Nächste Seite, dann auf letzten Eintrag ziehen
                            else if ((lastPageIndex < possibleDrop?.dropIndex) && rowIndex === pageSize) {
                              setPossibleDrop({...possibleDrop, dropIndex: lastPageIndex, position: "below"});
                            }
                            // Vorherige Seite, dann auf letzten Eintrag ziehen
                            else if (
                              (firstPageIndex > possibleDrop?.dropIndex)
                              && (rowIndex === ((
                                page === Math.ceil(totalRows / pageSize) - 1 ? (totalRows % pageSize - 1) : pageSize - 1
                              )))
                            ) {
                              setPossibleDrop({
                                ...possibleDrop,
                                dropIndex: (page === Math.ceil(totalRows / pageSize) - 1)
                                  ? page * pageSize + (totalRows % pageSize - 1)
                                  : lastPageIndex,
                                position: "below"
                              });
                            } else {
                              onDragOver(e, rowIndex + firstPageIndex);
                            }
                          }
                        }}
                        onDragEnd={e => {
                          (draggable && !savingOrder) ? onDragEnd(e) : setPossibleDrop(null);
                        }}
                      >
                        {draggable && <td><DragIcon/></td>}
                        <RowContext.Provider value={row}>{tds}</RowContext.Provider>
                      </tr>
                      {
                        !!possibleDrop
                        && possibleDrop.dropIndex === rowIndex + page * pageSize
                        && possibleDrop.position === "below"
                        && <tr className="drop-placeholder">{dragRow}</tr>
                      }
                    </Fragment>
                  );
                }
              )
            }
          </tbody>
        </table>
      </div>
      {
        draggable &&
        <Button
          text="Reihenfolge speichern" disabled={!orderChanged || savingOrder} onClick={() => saveOrder()}
          icon={savingOrder ? <LoaderIcon/> : null}
        />
      }
      <div className={"table-pagination" + (possibleDrop ? " drag" : "")}>
        {
          !showLoading && (totalRows > pageSize || totalRows > rows.length) &&
          <Pagination
            total={totalRows} page={page} pageSize={pageSize} setPage={setPage}
            onDragOver={(e, pageIndex) => {
              if (pageIndex > page) {
                onDragOver(e, pageIndex * pageSize, false);
              } else if (pageIndex < page) {
                onDragOver(e, pageIndex * pageSize + pageSize - 1, false);
              }
            }}
          />
        }
      </div>
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </div>
  );
}
